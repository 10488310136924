import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div>
      <div className="about-main-div">
        <div className="aboutButtonDiv">
          <h2>About Us</h2>
          <div className="about-btn">
            <a href="" className="about-btn-link">
              Home <span className="separator"> &gt; </span> About Us
            </a>
          </div>
         
        </div>
      </div>
      <div className="GEMCP-flex-div">
    {/* <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div> */}
        </div>
          
    </div>
  );
}

export default AboutUs;
