import React, { useState, useEffect } from 'react';
import './UpcomingEvent.css';
import Img1 from '../assets/EventImg/Event-img-1.jpg';
import Img2 from '../assets/EventImg/Event-img-2.jpg';
import Img3 from '../assets/EventImg/Event-img-3.jpg';
import Img4 from '../assets/EventImg/Event-img-4.jpg'
import PastEvent from './PastEvent'; // Import the PastEvent component

const UpcomingEvent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    eventTitle: '',
    eventDateTime: '',
    name: '',
    phone: '',
    email: '',
    tickets: 1,
    adults: 0,
    children: 0,
  });

  const events = [
    {
      title: 'Music Concert',
      location: 'Central Park',
      date: '2024-10-15',
      time: '7:00 PM',
      img: Img1,
    },
    {
      title: 'Art Exhibition',
      location: 'Noida Sec-62',
      date: '2024-10-20',
      time: '10:00 AM',
      img: Img2,
    },
    {
      title: 'Trade Expo',
      location: 'Gaur City',
      date: '2024-10-10', // Example past event
      time: '10:00 AM',
      img: Img3,
    },
    {
        title: 'Trade Expo',
        location: 'Gaur City',
        date: '2024-10-15', // Example past event
        time: '10:00 AM',
        img: Img4,
      },
  ];

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  useEffect(() => {
    const now = new Date();
    const upcoming = events.filter(event => new Date(event.date) > now);
    const past = events.filter(event => new Date(event.date) <= now);

    setUpcomingEvents(upcoming);
    setPastEvents(past);
  }, [events]);


  const openModal = (event) => {
    setFormData({
      ...formData,
      eventTitle: event.title,
      eventDateTime: `${event.date} at ${event.time}`,
    });
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    closeModal();
  };

  return (
    <div className="upcoming-events">
      <h2>Upcoming Events</h2>
      <div className="card-container">
        {upcomingEvents.map((event, index) => (
          <div className="event-card" key={index}>
            <img src={event.img} alt={event.title} className="event-image" />
            <div className="event-details">
              <h3>{event.title}</h3>
              <p>{event.location}</p>
              <p>{event.date} at {event.time}</p>
              <button onClick={() => openModal(event)} className="book-button">Book Now</button>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={closeModal}>✖</button>
            <h3>Book Tickets for {formData.eventTitle}</h3>
            <form onSubmit={handleSubmit}>
              <div>
                <input type="text" name='eventDateTime' value={formData.eventDateTime} disabled />
              </div>
              <div>
                <label>Name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />
              </div>
              <div>
                <label>Phone:</label>
                <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
              </div>
              <div>
                <label>Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
              </div>
              <div>
                <label>Number of Tickets:</label>
                <select name="tickets" value={formData.tickets} onChange={handleChange} required>
                  {[...Array(5).keys()].map(i => (
                    <option key={i} value={i + 1}>{i + 1}</option>
                  ))}
                </select>
              </div>
              {formData.tickets > 1 && (
                <>
                  <div>
                    <label>Number of Adults:</label>
                    <input type="number" name="adults" value={formData.adults} onChange={handleChange} min="0" />
                  </div>
                  <div>
                    <label>Number of Children:</label>
                    <input type="number" name="children" value={formData.children} onChange={handleChange} min="0" />
                  </div>
                </>
              )}
              <button type="submit">Submit</button>
              <button type="button" onClick={closeModal}>Cancel</button>
            </form>
          </div>
        </div>
      )}

      {/* Display Past Events */}
      {pastEvents.length > 0 && (
        <div>
          <h2>Past Events</h2>
          <PastEvent events={pastEvents} />
        </div>
      )}
    </div>
  );
};

export default UpcomingEvent;
