import React, { useState } from "react";
import India from "@svg-maps/india";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";
import './NewGeoEcoSystem.css';
import stateColors from '../JsonFolder/stateColors.json'; // Adjust this path as necessary

const stateData = {
  "Uttar Pradesh": { chairMan: "path_to_image_1.jpg", coChairMan: "path_to_image_2.jpg" },
  "Maharashtra": { chairMan: "path_to_image_3.jpg", coChairMan: "path_to_image_4.jpg" },
  // Add more states as needed
};

function NewGeoEcoSystem() {
  const [selectedState, setSelectedState] = useState(null);
  const [hoveredState, setHoveredState] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });

  const onLocationClick = (event) => {
    const stateName = event.target.getAttribute("name");
    if (stateData[stateName]) {
      setSelectedState({ name: stateName, ...stateData[stateName] });
    } else {
      setSelectedState(null);
    }
  };

  const onLocationHover = (event) => {
    const stateName = event.target.getAttribute("name");
    setHoveredState(stateName);

    const rect = event.target.getBoundingClientRect();
    setHoverPosition({
      x: rect.left + rect.width / 0,
      y: rect.top + rect.height / 0.8,
    });
  };

  const onLocationLeave = () => {
    setHoveredState(null);
  };

  return (
    <div className="main-div-of-map">
      <div className="map-main-div">
        <div className="btn-div-map">
          {selectedState && (
            <div className="map-detail-container">
              <div className="map-detail-list">
                <h4 className="ecoList">Chair Man</h4>
                <img src={selectedState.chairMan} alt="Chair Man" style={{ width: '130px', paddingTop: '10px' }} />
              </div>
              <div className="map-detail-list">
                <h4 className="ecoList">Co-Chair Man</h4>
                <img src={selectedState.coChairMan} alt="Co-Chair Man" style={{ width: '130px', paddingTop: '10px' }} />
              </div>
              <div className="map-detail-list">
                <h4 className="ecoList">State: {selectedState.name}</h4>
              </div>
            </div>
          )}
        </div>
        <div className="map-container">
          <SVGMap
            map={India}
            onLocationClick={onLocationClick}
            onLocationMouseOver={onLocationHover}
            onLocationMouseOut={onLocationLeave}
            locationStyle={(location) => ({
              fill: stateColors[location.name] || "rgb(214, 214, 218)", // Default color
              stroke: "#666",
              strokeWidth: 0.3,
              
            })}
          />
          
          {hoveredState && (
            <div className="hover-info" style={{ left: hoverPosition.x, top: hoverPosition.y }}>
              {hoveredState}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewGeoEcoSystem;
