import React from 'react';

const PastEvent = ({ events = [] }) => { // Default to an empty array
  return (
    <ul>
      {events.map((event, index) => (
        <li key={index}>
          <h3>{event.title}</h3>
          <p>{event.location}</p>
          <p>{event.date} at {event.time}</p>
        </li>
      ))}
    </ul>
  );
};

export default PastEvent;
