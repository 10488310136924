import React, { useState } from 'react';
import PastEvent from './PastEvent';
import UpcomingEvent from './UpcomingEvent';
import './Event.css';


const Event = () => {
  const [activeEvent, setActiveEvent] = useState('');

  const handleItemClick = (event) => {
    setActiveEvent(event);
  };

  const renderContent = () => {
    switch (activeEvent) {
      case 'Past Event':
        return < PastEvent />;
      case 'Upcoming Event':
        return <UpcomingEvent />;
      // case 'National Events':
      //   return <div>National Events Content</div>;
      // case 'St Level Events':
      //   return <div>St Level Events Content</div>;
      default:
        return <div><p>Please select an event to see the details.</p></div>;
    }
  };

  return (
    <div>
      <div className="Event-main-div">
        <div className="EventButtonDiv">
          <h2>Events</h2>
          <div className="Event-btn">
            <a href="" className="Event-btn-link">
              Home <span className="separator"> &gt; </span> Event
            </a>
          </div>
        </div>
      </div>

      {/* <div className="event-flex-div">
        <div className="important-link">
          <a href="">Important Links</a>
        </div>
      </div> */}

      {/* <div className="Event-Ani-cont">
        <h2>Event</h2>
      </div> */}

      <div className="event-link">
        <ul>
          {['Past Event', 'Upcoming Event',].map((event) => (
            <li key={event} onClick={() => handleItemClick(event)} className={activeEvent === event ? 'active' : ''}>
              <a href="#" onClick={(e) => e.preventDefault()}>{event}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="event-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Event;
