import React from 'react'
import './SustainabilityPage.css';

const SustainabilityPage = () => {
  return (
    <div>
      <div className="Sustainability-main-div">
        <div className="SustainabilityButtonDiv">
          <h2>ASSOCHAM Sustainability Initiatives</h2>
          <div className="Sustainability-btn">
            <a href="" className="Sustainability-btn-link">
              Home <span className="separator"> &gt; </span> ASSOCHAM Sustainability Initiatives
            </a>
          </div>
         
        </div>
      </div>
      <div className="GEMCP-flex-div">
    {/* <div className="important-link">
          <a href="">Important Links</a>
        </div>
        <div className="count-div">
            <h2></h2>
        </div> */}
        </div>
          
    </div>
  )
}

export default SustainabilityPage
