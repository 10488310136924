import React from 'react';
import { Link } from 'react-router-dom';
import './GemCps.css';
// import samplepaper from '../assets/contact-bkg.jpg'
const GemCps = () => {
    const performanceData = [
        { marks: '90 and above', performance: 'Extraordinary performance' },
        { marks: '70 to 89', performance: 'Excellent performance' },
        { marks: '50 to 69', performance: 'Good performance' },
        { marks: 'Below 50', performance: 'Not clear' },
    ];

    return (
        <div>
            <div className="main-cps-div">
                <div className="gem-para-content">
                    <h2>ASSOCHAM GEM Certified Professional (GEM CP) Online Test registration steps –</h2>
                    <p>
                        ASSOCHAM has initiated the <b>“GEM Sustainability (Green) Certification Program” </b> with an objective to promote environment friendly green building design and construction. Through this initiative, ASSOCHAM awards the Sustainability Certification Rating to Housing, Urban Development, Residential, Commercial, Hotels, College, Universities, Schools and Factory buildings and developments. All existing, new and upcoming buildings can participate in this program.
                    </p>
                    <p>
                        You can become a GEM CP by taking the GEM CP test remotely. You can take the test in the comfort of your home or workplace. This convenience will be valuable to you. ASSOCHAM team of experts has made this possible for you.
                    </p>
                    <p>
                        Use the following steps to register yourself for GEM CP online test, make online/offline payment, schedule the test and appear for the test.
                    </p>
                </div>

                <div className="links-div">
                    <h2>GEM CP online test registration steps –</h2>
                    <div className="ul-link">
                        <ul className='list-itmes-cps'>
                            <li>GEM CP online test registration steps –</li>
                            <p>Register for GEM CP online test using the following link –</p>
                            <a href="">link</a>

                            <li>Step-2 – Online payment (call us if you want to make payments through cheque / DD / NEFT etc.)</li>
                            <p>
                                Once your registration is done successfully, you will get the following link to make the payment for GEM CP online test fee = Rs. 5,900 - For Professionals / Rs. 4,720 - For Faculties & Students (Including GST)
                            </p>
                            <p>You can make online payment using the following link –</p>
                            <a href="">payment link</a>

                            <li>Step-3 – Schedule your test</li>
                            <p>A link like this will be sent to you to schedule your online test – (SAMPLE)</p>
                            <a href="">invoice</a>
                            <p>You can schedule the online test as per your convenience between Monday to Friday 10 am to 5 pm.</p>

                            <li>Step-4 – Take the online test as per your schedule</li>
                            <p>A link like this will be sent to you to take the online test as per your schedule – (SAMPLE)</p>
                            <a href="">quiz invoice</a>

                            <li>Your test will be started on the scheduled time only. Paste the above link in a new tab at or just after the scheduled time and press enter. Your test will be started.</li>

                            <li>You will be given 5 minutes to read the instructions and 90 minutes for taking the test. Read all the instructions and questions carefully.</li>

                            <li>
                                There will be 100 questions in the test (Both objective type-single answer and True/False).
                            </li>

                            <li>During the test, you can move to any previous or next questions by clicking on the “Previous” or “Next” tab respectively.</li>

                            <li>You can review all the attempted questions by clicking on the “Review & Submit” tab once.</li>

                            <li>Once you are in the Test Review section, you can move to any question by just clicking on that question directly.</li>

                            <li>You can finally submit the test by clicking on the “Final Submit” tab.</li>

                            <li>Do not close the test window during the test.</li>
                            <li>Pass criteria –</li>
                            <div className="table-div">
                                <table className='table-body' style={{ width: '25%', border: '1px solid black', borderCollapse: 'collapse', textAlign: 'justify', justifyContent: 'center' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid black', padding: '8px' }}>Marks Obtained</th>
                                            <th style={{ border: '1px solid black', padding: '8px' }}>Performance Criteria</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {performanceData.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ border: '1px solid black', padding: '8px' }}>{item.marks}</td>
                                                <td style={{ border: '1px solid black', padding: '8px' }}>{item.performance}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <li>Step-5 GEM Sustainability Certification Program reference guide can be downloaded from our website for FREE using the following link –</li>
                            <a href="">news links</a>
                            <p><b>We would appreciate your valuable feedback about the test.</b></p>
                            <b>All the best! </b>
                        </ul>
                    </div>
                    <div className="button-div-cps">
                        <ul>
                            <Link to='/register'><li className='Cps-btn'>Register Yourself</li></Link>
                            <Link to='/sample-paper'><li className='Cps-btn'>Sample Paper</li></Link>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GemCps;
