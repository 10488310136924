// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
// import logo1 from '../assets/logo-img2.png';
// import logo2 from '../assets/logo-img1.png';
// import logo3 from '../assets/GemNew_Logo.jpg';
// import './NewNavbar.css';

// const NewNavbar = () => {
//     const [menuOpen, setMenuOpen] = useState(false);
//     const [isSticky, setIsSticky] = useState(false);

//     useEffect(() => {
//         const handleScroll = () => {
//             const sticky = window.scrollY > 0;
//             setIsSticky(sticky);
//         };

//         window.addEventListener('scroll', handleScroll);
//         return () => {
//             window.removeEventListener('scroll', handleScroll);
//         };
//     }, []);

//     return (
//         <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
//             <div className="logo-container">
//                 <Link to="/"><img src={logo1} alt="Logo 1" className="logo" /></Link>
//                 {/* <Link to="/"><img src={logo2} alt="Logo 2" className="logo" /></Link> */}
//                 <Link to="/"><img src={logo3} alt="Logo 2" className="logo" /></Link>
//             </div>

//             <div
//                 className={`menu-icon ${menuOpen ? 'rotate' : ''}`}
//                 onClick={() => setMenuOpen(!menuOpen)}
//             >
//                 <FontAwesomeIcon icon={menuOpen ? faXmark : faBars} />
//             </div>

//             <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
//                 <li><Link to="/about">About Us</Link></li>
//                 <li><Link to="/gemGreen">GEM Green Certification</Link></li>
//                 <li><Link to="/Sustainability">Sustainability Solutions</Link></li>
//                 <li className="dropdown">
//                     <Link to="/ecoSystem">GEM Ecosystem</Link>
//                     <ul className="dropdown-menu">
//                         <li><Link to="/chapters">GEM Chapters</Link></li>
//                         <li><Link to="/eco-products">GEM Eco-Products</Link></li>
//                         <li><Link to="/green-building-certification">GEM Green Building Certification</Link></li>
//                         <li><Link to="/cps">GEM CPs</Link></li>
//                     </ul>
//                 </li>
//                 <li><Link to="/gemCp">Certified Professionals</Link></li>
//                 <li><Link to="/events">Events</Link></li>
//                 <li><Link to="/register">Register For Projects</Link></li>
//                 <li><Link to="/resources">Resources</Link></li>
//             </ul>
//         </nav>
//     );
// };

// export default NewNavbar;

// new code

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import logo1 from '../assets/New_logo_1.png';
import logo3 from '../assets/Gem_new_Logo2.png';
import logo4 from '../assets/logo-img2-oldst.png'
import './NewNavbar.css';

const NewNavbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const sticky = window.scrollY > 0;
            setIsSticky(sticky);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to toggle the menu
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Function to close the menu
    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
            <div className="logo-container">
                <Link to="/"><img src={logo4} alt="Logo 1" className="logo" /></Link>
                {/* <Link to="/"><img src={logo1} alt="Logo 1" className="logo" /></Link> */}
                <Link to="/"><img src={logo3} alt="Logo 2" className="logo" /></Link>
            </div>

            <div
                className={`menu-icon ${menuOpen ? 'rotate' : ''}`}
                onClick={toggleMenu} // Toggle the menu on icon click
            >
                <FontAwesomeIcon icon={menuOpen ? faXmark : faBars} />
            </div>

            <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
                <li><Link to="/about" onClick={closeMenu}>About Us</Link></li>
                <li><Link to="/gemGreen" onClick={closeMenu}>GEM Green Certification</Link></li>
                <li><Link to="/Sustainability" onClick={closeMenu}>Sustainability Solutions</Link></li>
                <li className="dropdown">
                    <Link to="/ecoSystem" onClick={closeMenu}>GEM Ecosystem</Link>
                    <ul className="dropdown-menu">
                        <li><Link to="/chapters" onClick={closeMenu}>GEM Chapters</Link></li>
                        <li><Link to="/eco-products" onClick={closeMenu}>GEM Eco-Products</Link></li>
                        <li><Link to="/green-building-certification" onClick={closeMenu}>GEM Green Building Certification</Link></li>
                        <li><Link to="/cps" onClick={closeMenu}>GEM CPs</Link></li>
                    </ul>
                </li>
                <li><Link to="/gemCp" onClick={closeMenu}>Certified Professionals</Link></li>
                <li><Link to="/events" onClick={closeMenu}>Events</Link></li>
                <li><Link to="/register" onClick={closeMenu}>Register For Projects</Link></li>
                <li><Link to="/resources" onClick={closeMenu}>Resources</Link></li>
            </ul>
        </nav>
    );
};

export default NewNavbar;
