import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';

// Import logo images from assets
import logo1 from '../assets/logo-img2-oldst.png';
import logo2 from '../assets/logo-img1.png';
import logo3 from '../assets/Gem_new_Logo2.png';

const Footer = () => {
  return (
    <footer className="footer-container">
      {/* First div with logos and content */}
      <div className="footer-logo-content">
        <div className="footer-logos">
          {/* <img src={logo1} alt="Logo 1" className="footer-logo" />
          <img src={logo2} alt="Logo 2" className="footer-logo" /> */}
          <Link to="/"><img src={logo1} alt="Logo 1" className="logo" /></Link>
                {/* <Link to="/"><img src={logo2} alt="Logo 2" className="logo" /></Link> */}
                <Link to="/"><img src={logo3} alt="Logo 2" className="logo" /></Link>
        </div>
        <p>THE ASSOCIATED CHAMBERS OF COMMERCE & INDUSTRY OF INDIA</p>
      </div>

      {/* Second div with Quick Links */}
      <div className="footer-quick-links">
        <h4>Quick Links</h4>
        <ul>
          <li> <FontAwesomeIcon icon={faAnglesRight} /> About ASSOCHAM</li>
          <li> <FontAwesomeIcon icon={faAnglesRight} /> About GEM Rating</li>
          <li> <FontAwesomeIcon icon={faAnglesRight} /> GEM Rating Systems</li>
          <li> <FontAwesomeIcon icon={faAnglesRight} /> Project Registration</li>
          <li> <FontAwesomeIcon icon={faAnglesRight} /> Payment</li>
        </ul>
      </div>

      {/* Third div with Contact Us */}
      <div className="footer-contact">
        <h4>Contact Us</h4>
        <ul className='footerAddress'>
        <li>
          <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
         <p> 4th Floor, YMCA Cultural Centre and Library Building, 01, Jai Singh Road, New Delhi - 110001</p>
          </li>
        
        <li>
          <FontAwesomeIcon icon={faPhone} className="contact-icon" />
          <a href="tel:+919315700465"><p> +91 9315700465</p></a>
          </li>
        
        <li>
          <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
          <a href="mailto:amitkumar.sharma@assocham.com"><p>amitkumar.sharma@assocham.com</p></a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
